import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import log from 'loglevel';

const Cgu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url') || location.state?.redirect_url || '/';
  const scormUrl = searchParams.get('scorm_url') || location.state?.scorm_url;

  const [privacy, setPrivacy] = useState(false);
  const [failed, setFailed] = useState(false);
  const [info, setInfo] = useState<{ message?: string }>({});

  const submit = async () => {
    if (!privacy) {
      setFailed(true);
      setInfo({ message: "Veuillez accepter les conditions générales d'utilisation" });
      return;
    }

    try {
      await window.sdk.user().setCguAcceptationDate();
    } catch (error) {
      log.error('Error setting cgu acceptation date:', error);
      setFailed(true);
      setInfo({
        message:
          "Une erreur est survenue lors de l'enregistrement de votre acceptation des conditions générales d'utilisation"
      });
      return;
    }

    if (scormUrl) {
      window.location.href = scormUrl;
      return;
    }

    navigate(redirectUrl);
  };

  return (
    <div className="legacy">
      <div className="centered-row flex min-h-[calc(100vh)] flex-col items-center justify-center py-8">
        <Practicio className="mb-6 h-auto w-52 max-w-full fill-accent" />
        <h2 className="title">
          <Translation keyName="cgu.title" />
        </h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mt-4" controlId="privacy">
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                name="privacy"
                checked={privacy}
                onChange={(e) => setPrivacy(e.target.checked)}
                required
                isInvalid={!privacy && failed}
                data-testid="accept-tos-form"
              />
              <Form.Check.Label className="cursor-pointer transition-colors">
                <Translation keyName="cgu.checkbox-text" />{' '}
                <a
                  href={window.policyUri}
                  className="link text-current hover:text-current"
                  target="_blank"
                  rel="noreferrer">
                  <Translation keyName="cgu.link-text" />
                </a>
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>

          {failed && (
            <Alert className="alert mt-4" variant="danger">
              {info.message}
            </Alert>
          )}

          <button
            className="cta cta--accent mx-auto mt-4 block"
            data-testid="cgu-submit-btn"
            type="submit"
            onClick={submit}>
            <Translation keyName="cgu.submit">Continuer</Translation>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Cgu;
