import log from 'loglevel';
import * as Sentry from '@sentry/browser';
import Cognito from '../Utils/Cognito';
import CookieManager from '../Utils/CookieManager';
import { v4 as uuidv4 } from 'uuid';

export default class User {
  isLogged = false;
  cognitoDomain = `https://practicio-${process.env.REACT_APP_ENV}.auth.eu-west-3.amazoncognito.com`;

  constructor(sdk) {
    this.sdk = sdk;
    this.cognito = new Cognito(sdk);
    this.refuseAccess = false;
  }

  async init() {
    try {
      // Handle SSO callback if code is present
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        const ssoResult = await this.handleSSOCallback(code);
        if (ssoResult.state === 'success') {
          return;
        }
      }

      await this.cognito.init();
      const session = await this.cognito.validateSession();

      if (!session || session.state !== 'VALID') {
        return;
      }

      log.debug('Initializing user session: ', session);

      // Set authorization headers if access token exists
      const accessToken = CookieManager.getCookie('accessToken');
      if (accessToken) {
        this.setAuthorizationHeaders(accessToken);
      }

      // Fetch user data
      const me = await this.sdk.fetchInternalAPI().get('/auth/me');
      if (!me) {
        log.debug('fail to call /auth/me in User.init method');
        return;
      }

      if (me.statusCode === 403) {
        log.debug('Invalid token, logging out user');
        await this.logout();
        return;
      }

      // Handle iframe session confirmation if needed
      if (this.sdk.isInIframe() && window.sdk.getParam('needCheck')) {
        this.handleIframeSessionConfirmation(session, me);
      } else {
        this.createUser(session, me);
        this.sdk.event().emit('userLogged');
      }
    } catch (err) {
      log.debug(err);
    }
  }

  setAuthorizationHeaders(token) {
    const authHeader = `Bearer ${token}`;
    this.sdk.fetch().addGlobalHeader('Authorization', authHeader);
    this.sdk.fetchInternalAPI().addGlobalHeader('Authorization', authHeader);
  }

  handleIframeSessionConfirmation(session, userData) {
    this.sdk.event().emit('needSesionConfirmation', userData, (confirmation) => {
      if (confirmation) {
        this.createUser(session, userData);
        this.sdk.event().emit('userLogged');
      } else {
        this.logout();
      }
    });
  }

  async validateSession() {
    let res = await this.cognito.validateSession();
    //log.debug('validateSession', res);
    if (res.state === 'EXPIRED') {
      const refreshResult = await this.refreshSession();
      if (refreshResult?.state === 'SUCCESS') {
        // Re-fetch user data with new tokens
        const me = await this.sdk.fetchInternalAPI().get('/auth/me');
        if (me) {
          this.createUser({ id: this.sessionID, token: refreshResult.accessToken }, me);
          return true;
        }
      }
      return false;
    } else if (res.state === 'VALID') {
      this.setAuthorizationHeaders(res.accessToken);
      return true;
    }
    return false;
  }

  async refreshSession() {
    let newSession = await this.cognito.refreshSession();
    if (newSession.state === 'SUCCESS') {
      this.setAuthorizationHeaders(newSession.token);
      
      CookieManager.setCookie('accessToken', newSession.accessToken);
      CookieManager.setCookie('idToken', newSession.idToken);

      this.isLogged = true;

      this.checkAndRedirectToScorm();

      return newSession;
    } else {
      log.debug('Token refresh failed');
      await this.logout();
      return null;
    }
  }

  async createSessionFromSSOToken(idToken, accessToken, refreshToken) {
    const session = {
      id: uuidv4(),
      token: accessToken
    };

    // Store tokens in cookies
    CookieManager.setCookie('accessToken', accessToken);
    CookieManager.setCookie('idToken', idToken);
    CookieManager.setCookie('refreshToken', refreshToken);

    return session;
  }

  async handleSSOCallback(code) {
    try {
      // Initialize Cognito first
      await this.cognito.init();

      // Exchange code for tokens
      const tokenEndpoint = `${this.cognitoDomain}/oauth2/token`;
      const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;

      // Get the current URL's origin for the redirect_uri
      const currentUrl = new URL(window.location.href);
      const redirectUri = currentUrl.origin;

      const params = new URLSearchParams({
        grant_type: 'authorization_code',
        code: code,
        client_id: clientId,
        redirect_uri: redirectUri
      });

      const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: params.toString()
      });

      if (!response.ok) {
        const error = await response.text();
        log.error('Token exchange failed:', error);
        throw new Error('Failed to exchange code for tokens');
      }

      const tokens = await response.json();

      // Create session from tokens
      const session = await this.createSessionFromSSOToken(
        tokens.id_token,
        tokens.access_token,
        tokens.refresh_token
      );

      // Set headers
      this.setAuthorizationHeaders(session.token);

      // Call register to create the account if it doesnt exists, if it already exists it will simply return a 200 with `{"state":"fail","info":{"message":"User already exists"}}`
      await this.register(false);

      // Get user info
      const me = await this.sdk.fetchInternalAPI().get('/auth/me');
      if (!me) {
        throw new Error('Failed to fetch user info');
      }

      // Create user
      this.createUser(session, me);

      // Log activity
      await this.sdk.usersActivity().createOne('Login', {
        State: 'Success',
        Method: 'SSO'
      });

      // Emit userLogged event
      this.sdk.event().emit('userLogged');

      this.redirectAfterSSOCallback();

      return {
        state: 'success'
      };
    } catch (error) {
      log.error('SSO callback error:', error);
      return {
        state: 'fail',
        info: {
          message: 'Failed to process SSO login'
        }
      };
    }
  }

  async login(body) {
    let cognitoLogin = await this.cognito.login(body.username, body.password);

    if (cognitoLogin.state !== 'SUCCESS') {
      log.debug('--- DEBUG: Login res.state !== success)');
      return cognitoLogin;
    }

    const cognitoUserSession = cognitoLogin.session;
    this.setAuthorizationHeaders(cognitoUserSession.accessToken.jwtToken);

    // If user exists but is not active, we need to register them with accepted CGU
    const checkEmailResponse = await this.checkEmail(body.username);
    if (
      checkEmailResponse.userExists &&
      checkEmailResponse.hasValidatedEmail &&
      !checkEmailResponse.isActive
    ) {
      await this.register(true);
    }

    // wait to be sure that the user is recorded in DynamoDB before call /me
    await new Promise((resolve) => setTimeout(resolve, 500));

    const me = await this.sdk.fetchInternalAPI().get('/auth/me');

    const session = {
      id: uuidv4(),
      token: cognitoUserSession.accessToken.jwtToken
    };

    this.createUser(session, me);

    // Log to DynamoDB
    window.sdk.usersActivity().createOne('Login', { State: 'Success' });
    this.redirectAfterLogin(body);

    return cognitoLogin;
  }

  redirectAfterLogin(body) {
    const redirectUrl = window.sdk.getParam('redirect_url') || body.redirect_url;
    const scormUrl = window.sdk.getParam('scorm_url');
    const isExternal = scormUrl ? true : false;

    let goToUrl = '/';

    if (scormUrl) {
      goToUrl = scormUrl;
    } else if (redirectUrl) {
      goToUrl = redirectUrl.split('needCheck').join('');
      goToUrl = goToUrl.replace(/^.*\/\/[^/]+/, ''); // remove protocol and domain
    }

    if (!this.cguAcceptationDate) {
      this.sdk.event().emit('goTo', {
        url: `/cgu?${isExternal ? 'scorm_url' : 'redirect_url'}=${goToUrl}`,
        isExternal
      });
      return;
    }

    this.sdk.event().emit('goTo', { url: goToUrl, isExternal });
  }

  redirectAfterSSOCallback() {
    const scormUrl = CookieManager.getCookie('scorm_url');

    if (scormUrl) {
      log.debug('Found scorm_url cookie, redirecting to:', scormUrl);
      CookieManager.deleteCookie('scorm_url');

      this.sdk.event().emit('goTo', {
        url: this.cguAcceptationDate ? scormUrl : `/cgu?scorm_url=${scormUrl}`,
        isExternal: true
      });
      return;
    }

    // Default redirect to home
    this.sdk.event().emit('goTo', { url: '/', isExternal: false });
  }

  checkAndRedirectToScorm() {
    const scormUrl = window.sdk.getParam('scorm_url');

    if (scormUrl) {
      this.sdk.event().emit('goTo', { url: scormUrl, isExternal: true });
    }
  }

  async register(areCguAccepted) {
    let res = await this.sdk
      .fetchInternalAPI()
      .post('/auth/register', { body: { areCguAccepted } });

    // Log to DynamoDB
    if (res.state === 'success') {
      window.sdk.usersActivity().createOne('ActivateUser', { UserID: res.info.UserID });
    }

    return res;
  }

  async logout() {
    // Log to DynamoDB
    window.sdk.usersActivity().createOne('Logout', {});

    if (window.sdk.fullscreenStatus) {
      this.sdk.closeFullscreen();
    }

    this.sdk.event().emit('fetchStarted');

    // Sign out from Cognito and clear all auth cookies
    await this.cognito.logout();

    // Clear auth cookies
    CookieManager.deleteCookie('accessToken');
    CookieManager.deleteCookie('idToken');
    CookieManager.deleteCookie('refreshToken');

    // Remove User from Sentry
    Sentry.setUser(null);

    // Remove Zendesk prefill values
    window.zE('webWidget', 'prefill', {});

    this.sdk.event().emit('userLogOut');
  }

  createUser(session, userInfo) {
    this.isLogged = true;

    // Get session data
    this.sessionID = session.id;

    // Get user data from the userInfo input
    this.userID = userInfo.UserID;
    this.email = userInfo.Email;
    this.name = userInfo.Name;
    this.firstName = userInfo.FirstName;
    this.lastName = userInfo.LastName;
    this.role = userInfo.Role;
    this.entity = userInfo.Entity;
    this.availableExercises = userInfo.availableExercises;
    this.latestSessionDate = userInfo.latestSessionDate;
    this.latestFTUEOnboardingViewDate = userInfo.latestFTUEOnboardingViewDate;
    this.cguAcceptationDate = userInfo.CguAcceptationDate;

    // Set User in Sentry
    Sentry.setUser({
      id: session.userID
    });

    // Prefill Zendesk values
    window.zE('webWidget', 'prefill', {
      name: {
        value: `${this.firstName} ${this.lastName}`,
        readOnly: true // optional
      },
      email: {
        value: this.email,
        readOnly: true // optional
      }
    });
  }

  async forgotPassword(data) {
    return this.cognito.forgotPassword({ email: data.email });
  }

  async passwordEdit(data) {
    return this.cognito.confirmForgotPassword({
      email: data.email,
      code: data.verificationCode,
      newPassword: data.password
    });
  }

  async checkEmail(email) {
    const body = {
      email
    };

    return this.sdk.fetchInternalAPI().post('/auth/check-email', { body });
  }

  async registerPending(data) {
    let iStartTime = new Date();
    let PendingUserID =
      iStartTime.getFullYear().toString().padStart(4, '0') +
      (iStartTime.getMonth() + 1).toString().padStart(2, '0') +
      iStartTime.getDate().toString().padStart(2, '0') +
      iStartTime.getHours().toString().padStart(2, '0') +
      iStartTime.getMinutes().toString().padStart(2, '0') +
      iStartTime.getSeconds().toString().padStart(2, '0') +
      iStartTime.getMilliseconds().toString().padStart(3, '0');
    //+ '-' + window.sdk.user().userID;
    let body = {
      ID: PendingUserID,
      Email: data.Email,
      Entity: data.Entity,
      FirstName: data.FirstName,
      LastName: data.LastName
    };
    let res = await this.sdk.fetch().post('/register/pending', { body });

    return res;
  }

  firtTimeUserExp() {
    let byPass = window.testMode.forceFirstTime;
    if (byPass) {
      if (byPass === 'noftue_user') return false;
      if (['unknown_user', 'known_user'].includes(byPass)) return byPass;
    }

    if (!this.latestSessionDate) {
      return 'unknown_user';
    }

    let latestSessionDateObj = new Date(this.latestSessionDate);
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    if (latestSessionDateObj < sixMonthsAgo) {
      return 'known_user';
    }
    return false;
  }

  skipFirstTimeUserExpOnboarding() {
    // Check if the user has already seen the FTUE onboarding in the last 6 months

    // If the user has not seen the FTUE onboarding, return false
    if (!this.latestFTUEOnboardingViewDate) return false;

    let latestFTUEOnboardingViewDateObj = new Date(this.latestFTUEOnboardingViewDate);
    let sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    // If the user has seen the FTUE onboarding in the last 6 months, return true
    return latestFTUEOnboardingViewDateObj > sixMonthsAgo;
  }

  async setCguAcceptationDate() {
    const res = await this.sdk.fetchInternalAPI().post('/auth/cgu-acceptation-date', {
      body: {}
    });

    this.cguAcceptationDate = res.cguAcceptationDate;
  }
}
