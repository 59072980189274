import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class GoOnstageBriefing extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ".");
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + ' does nothing for now.');
  }
}
