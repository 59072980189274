import log from 'loglevel';
import Utils from '../../../Utils/Utils';

export const NodePortDirection = {
  OUTPUT: 'output',
  INPUT: 'input'
};

export default class NodePort {
  Name = '';
  Direction = NodePortDirection.OUTPUT;
  ParentNode = null;
  ConnectedPorts = [];

  constructor(iName, iDirection, iParentNode) {
    this.Name = iName;
    this.Direction = iDirection;
    this.ParentNode = iParentNode;
  }

  Connect(iOtherPort) {
    this.ConnectedPorts.push(iOtherPort);
  }

  Disconnect(iOtherPort) {
    const index = this.ConnectedPorts.indexOf(iOtherPort);
    if (index > -1) {
      this.ConnectedPorts.splice(index, 1);
    }
  }

  ListPortConnections() {
    let connections = 'none';

    let connectionsList = this.ConnectedPorts.map((port) => {
      return port.Name + '-' + port.ParentNode.GetIdentity();
    });

    if (connectionsList.length > 0) {
      connections = connectionsList.join(', ');
    }

    return connections;
  }

  GetConnectionsCount() {
    return this.ConnectedPorts.length;
  }

  GetFirstConnectedNode() {
    if (this.ConnectedPorts.length > 0) {
      return this.ConnectedPorts[0].ParentNode;
    }

    return null;
  }

  GetConnectedNodes() {
    return this.ConnectedPorts.map((port) => {
      return port.ParentNode;
    });
  }

  async Activate(iSourcePort = null) {
    // Wait for the graph to be running
    const graph = this.ParentNode.Graph;
    while (!graph.IsRunning()) {
      await Utils.Sleep(graph.FrameDuration);
    }

    if (this.Direction === NodePortDirection.OUTPUT) {
      if (this.ParentNode.IsRewindMode()) {
        return;
      }

      this.ConnectedPorts.forEach((connectedPort) => {
        connectedPort.Activate(this);
      });
    } else if (this.Direction === NodePortDirection.INPUT) {
      const activationLink = Utils.CreateActivationLinkFromObjects(
        iSourcePort.ParentNode,
        iSourcePort,
        this.ParentNode,
        this
      );
      this.ParentNode.OnActivated(activationLink);
    } else {
      log.error(this.GetIdentity() + ' Port.Activate: Invalid direction: ' + this.Direction);
    }
  }

  TestActivateAllConnections(iTestReport) {
    this.ConnectedPorts.forEach((port) => {
      port.ParentNode.TestExecute(this.ParentNode, port, iTestReport);
    });
  }

  GetIdentity() {
    return this.ParentNode.GetIdentity() + '-' + this.Name;
  }
}
