import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class Delay extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  Duration = 1;

  // Internal values
  #StartTime = 0;
  #PauseTime = 0;
  #LogFrequency = 0.5;
  #LastLogTime = 0;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.Duration = iProperties.Duration;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", duration = " + this.Duration);
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.#StartTime = new Date().getTime();
    this.Update(iIsRewindMode);
  }

  Update() {
    if (this.IsActive()) {
      let timeSpent = 0.001 * (new Date().getTime() - this.#StartTime);

      // Log progress
      if (timeSpent / this.Duration > this.#LastLogTime + this.#LogFrequency) {
        log.debug(
          this.GetIdentity() +
            ' has been updated. Time spent:' +
            timeSpent +
            ' / ' +
            this.Duration +
            ' s.'
        );
        this.#LastLogTime = timeSpent / this.Duration;
      }

      if (timeSpent >= this.Duration) {
        log.debug(this.GetIdentity() + ' reached the end of its delay!');
        this.ActivateOutput();
      }
    }
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }

  Pause() {
    super.Pause();

    if (this.IsActive()) {
      // Save pause time
      this.#PauseTime = new Date().getTime();
    }
  }

  Resume() {
    super.Resume();

    if (this.IsActive()) {
      // Add the time spent in pause to the start time to avoid shortening the delay
      this.#StartTime += new Date().getTime() - this.#PauseTime;
    }
  }

  Skip() {
    if (!this.IsActive()) {
      return;
    }

    log.debug(this.GetIdentity() + ' has been skipped!');
    this.ActivateOutput();
  }

  PrintParameters() {
    //log.debug("Delay = " + this.Delay);
  }
}
