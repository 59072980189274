import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class ToWhomDetector extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Speech = new NodePort('Speech', 'input', this); // Connected to a string value node to get the speech detected string
  JeanneOutput = new NodePort('JeanneOutput', 'output', this);
  FranckOutput = new NodePort('FranckOutput', 'output', this);
  ClaraOutput = new NodePort('ClaraOutput', 'output', this);
  FranckClaraOutput = new NodePort('FranckClaraOutput', 'output', this);
  DefaultOutput = new NodePort('DefaultOutput', 'output', this);

  // Parameters

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID);
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }
    // Retrieve speech text from speech input node
    let speech = this.GetSourceText();

    log.debug(this.GetIdentity() + " has been activated with speech = '" + speech + "'.");

    this.AskAPI(speech);
  }

  GetSourceText() {
    let text = '';

    // Get the speech from the first connected node
    let speechNodes = this.Speech.GetConnectedNodes();
    if (speechNodes.length > 0) {
      text = speechNodes[0].GetStringValue();
    }

    return text;
  }

  async AskAPI(iSpeech) {
    let startTime = new Date();

    // Ask toWhomAPI
    let answer = await window.sdk.toWhomAPI().Ask(iSpeech);

    // Log to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'ToWhomAPI', // analyzer Engine
      answer['api-version'], // Analyzer Version
      'raw', // Analysis Status
      iSpeech, // Analysis Input
      startTime, // Start Time
      (new Date().getTime() - startTime.getTime()).toString(), // Analysis duration (milliseconds)
      JSON.stringify(['Jeanne', 'Franck', 'Clara', 'FranckClara', 'Default']), // Possible choices
      JSON.stringify(answer.prediction), // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );

    // Activate the corresponding output
    this.SetActive(false);
    switch (answer.prediction) {
      case 'Jeanne':
        log.debug(
          this.GetIdentity() + ': answer = Jeanne. Request result: ' + JSON.stringify(answer)
        );
        this.JeanneOutput.Activate();
        break;
      case 'Franck':
        log.debug(
          this.GetIdentity() + ': answer = Franck. Request result: ' + JSON.stringify(answer)
        );
        this.FranckOutput.Activate();
        break;
      case 'Clara':
        log.debug(
          this.GetIdentity() + ': answer = Clara. Request result: ' + JSON.stringify(answer)
        );
        this.ClaraOutput.Activate();
        break;
      case 'FranckClara':
        log.debug(
          this.GetIdentity() + ': answer = FranckClara. Request result: ' + JSON.stringify(answer)
        );
        this.FranckClaraOutput.Activate();
        break;
      default:
        log.debug(
          this.GetIdentity() +
            ': answer not found! Activating default output... Request result: ' +
            JSON.stringify(answer)
        );
        this.DefaultOutput.Activate();
        break;
    }
  }

  OnDeactivated() {
    // Stop current API call if running

    super.OnDeactivated();
  }

  PrintParameters() {}
}
