import log from 'loglevel';
import NodePort from './Shared/NodePort';

export default class BaseNode {
  // Ports
  Ports = {};

  // Parameters
  Graph;
  ID;
  Type;

  // Internal values
  m_ExecutionMode = 'Normal';

  constructor(iGraph, iProperties) {
    this.Graph = iGraph;
    this.ID = iProperties.ID;
    this.Type = iProperties.Type;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID);
  }

  Initialize() {
    this.RegisterPorts();
  }

  Update() {}

  RegisterPorts() {
    for (const propertyKey in this) {
      if (this[propertyKey] instanceof NodePort) {
        let port = this[propertyKey];
        let name = port.Name;

        //log.debug(this.GetIdentity() + ": Registering port '" + name + "'.");

        // Add the port to the Ports array
        this.Ports[name] = port;
      }
    }
  }

  GetInputPorts() {
    let inputPorts = [];
    Object.values(this.Ports).forEach((port) => {
      if (port.Direction === 'input') {
        inputPorts.push(port);
      }
    });

    return inputPorts;
  }

  GetOutputPorts() {
    let outputPorts = [];
    Object.values(this.Ports).forEach((port) => {
      if (port.Direction === 'output') {
        outputPorts.push(port);
      }
    });

    return outputPorts;
  }

  GetPortByName(iName) {
    // Check if iName is in the dictionary
    if (iName in this.Ports) {
      //log.debug(this.GetIdentity() + " GetPortByName: Port '" + iName + "' found.");
      return this.Ports[iName];
    } else {
      log.error(this.GetIdentity() + " GetPortByName: Port '" + iName + "' not found.");
      return null;
    }
  }

  GetIdentity() {
    return this.Type + '_' + this.ID;
  }

  GetDetailedIdentity() {
    return this.GetIdentity();
  }

  PrintName() {
    log.debug(this.GetIdentity());
  }

  SetTestMode(
    iTestMode // boolean
  ) {
    this.m_ExecutionMode = iTestMode ? 'Test' : 'Normal';
  }

  IsNormalMode() {
    return this.m_ExecutionMode === 'Normal';
  }
}
