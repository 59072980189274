import log from 'loglevel';
import BaseNode from './BaseNode';
import NodePort from './Shared/NodePort';

export default class ValueInt extends BaseNode {
  // Ports
  Set = new NodePort('Set', 'input', this);
  Get = new NodePort('Get', 'output', this);

  // Parameters
  ValueName = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.ValueName = iProperties.ValueName;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", name = " + this.ValueName);
  }

  SetValue(iValue) {
    log.debug(
      this.GetIdentity() + " SetValue: setting value of '" + this.ValueName + "' to " + iValue
    );
    this.Graph.SetIntValue(this.ValueName, iValue);
  }

  GetValue() {
    log.debug(
      this.GetIdentity() +
        " GetValue: getting value of '" +
        this.ValueName +
        "' = " +
        this.Graph.GetBoolValue(this.ValueName)
    );
    return this.Graph.GetIntValue(this.ValueName);
  }

  PrintParameters() {
    //log.debug("ValueBool: ID = " + this.ID + ", Name = " + this.Name + ".");
  }
}
