import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';
import { FEEDBACK_EVALUATIONS } from '../Solvers/DetailedFeedbacksSolver';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';
import Path from './Shared/Path';

export default class Act extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  ActName = '';
  ActNumber = 0;
  Paths = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.ActName = iProperties.ActName;
    this.ActNumber = iProperties.ActNumber;

    if (iProperties.Paths) {
      this.InitPaths(iProperties.Paths);
    }
  }

  InitPaths(iPaths) {
    iPaths.forEach((path) => {
      const newPath = new Path(path.ID);
      this.Paths.push(newPath);

      this[newPath.GetInputPortName()] = new NodePort(newPath.GetInputPortName(), 'input', this);
      this[newPath.GetOutputPortName()] = new NodePort(newPath.GetOutputPortName(), 'output', this);
    });
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.Graph.SetCurrentActNode(this);

    if (iIsRewindMode) {
      return;
    }

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.ActName,
        displayedName: this.ActName,
        inProgress: true
      }
    });

    this.ActivateOutput(iActivationLink);
  }

  ActivateOutput(iActivationLink) {
    this.SetActive(false);

    let outputPort = this.Output; // Default to main output

    if (iActivationLink) {
      const chosenPath = this.GetPathFromInput(iActivationLink.Target.Port);
      if (chosenPath) {
        outputPort = this[chosenPath.GetOutputPortName()];
      }
    }

    log.debug(`${this.GetIdentity()} activating output ${outputPort.GetIdentity()}`);
    outputPort.Activate();
  }

  GetPathFromInput(iInputPort) {
    return this.Paths.find((path) => path.GetInputPortName() === iInputPort.Name);
  }

  PrintParameters() {
    //log.debug("Act: ActName = " + this.ActName + ".");
  }

  OnActEvaluation() {
    const evaluation = this.SolveEvaluation();

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.ActName,
        displayedName: this.ActName,
        evaluation: evaluation,
        inProgress: false
      }
    });

    this.Graph.History.AddActCompletionEvent(this.ID, this.ActName, evaluation);
  }

  SolveEvaluation() {
    const userActionFeedbackEvents = this.Graph.History.GetUserActionsFeedbacksByActName(
      this.ActName
    );

    const userActionfeedbacks = userActionFeedbackEvents.map((event) => {
      const uaf = this.Graph.GetFullUserActionFeedbackData(
        event.Content.UserActionFeedbackID,
        event.Content.NodeID
      );
      uaf.IsMissedOpportunity = event.Content.IsMissedOpportunity;
      return uaf;
    });

    // Sort by priority and get highest priority UAF
    const highestPriorityFeedback = userActionfeedbacks.sort(
      (a, b) => b.PriorityRank - a.PriorityRank
    )[0];

    if (highestPriorityFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (
      highestPriorityFeedback.Tags.includes(USER_ACTION_TAGS.BAD_ACTION) ||
      highestPriorityFeedback.IsMissedOpportunity
    ) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }
}
