import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';
import Path from './Shared/Path';

export default class Scene extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  SceneName = '';
  ActNumber = null;
  SceneNumber = null;
  UnlockedTrophy = null;
  Summary = null;
  Context = null;
  Objectives = null;
  ShouldReplayPreviousVideo = null;
  Paths = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.SceneName = iProperties.SceneName;
    this.ActNumber = iProperties.ActNumber || null;
    this.SceneNumber = iProperties.SceneNumber || null;
    this.UnlockedTrophy = iProperties.UnlockedTrophy || null;
    this.Summary = iProperties.Summary || null;
    this.Context = iProperties.Context || null;
    this.Objectives = iProperties.Objectives || null;
    this.ShouldReplayPreviousVideo = iProperties.ShouldReplayPreviousVideo || false;

    if (iProperties.Paths) {
      this.InitPaths(iProperties.Paths);
    }
  }

  InitPaths(iPaths) {
    iPaths.forEach((path) => {
      const newPath = new Path(path.ID);
      this.Paths.push(newPath);

      this[newPath.GetInputPortName()] = new NodePort(newPath.GetInputPortName(), 'input', this);
      this[newPath.GetOutputPortName()] = new NodePort(newPath.GetOutputPortName(), 'output', this);
    });
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.Graph.CurrentSceneNode = this;

    if (iIsRewindMode) {
      return;
    }

    this.Graph.History.AddSceneActivation(
      this.ID,
      this.SceneName,
      this.ActNumber,
      this.SceneNumber,
      this.Summary,
      this.Context,
      this.Objectives,
      this.ShouldReplayPreviousVideo
    );

    if (this.UnlockedTrophy) {
      this.Graph.History.AddUnlockedTrophyEvent(this.ID, this.UnlockedTrophy);
    }

    this.ActivateOutput(iActivationLink);
  }

  ActivateOutput(iActivationLink) {
    this.SetActive(false);

    let outputPort = this.Output; // Default to main output

    if (iActivationLink) {
      const chosenPath = this.GetPathFromInput(iActivationLink.Target.Port);
      if (chosenPath) {
        outputPort = this[chosenPath.GetOutputPortName()];
      }
    }

    log.debug(`${this.GetIdentity()} activating output ${outputPort.GetIdentity()}`);
    outputPort.Activate();
  }

  GetPathFromInput(iInputPort) {
    return this.Paths.find((path) => path.GetInputPortName() === iInputPort.Name);
  }
}
