export default class Path {
  constructor(private ID: number) {}

  GetOutputPortName() {
    return `oPath${this.ID}`;
  }

  GetInputPortName() {
    return `iPath${this.ID}`;
  }
}
