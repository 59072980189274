import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';

export default class GPT3Classifier extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Speech = new NodePort('Speech', 'input', this);
  Default = new NodePort('Default', 'output', this);

  // Parameters
  GPT3Engine = 'text-davinci-002';

  Classes = [];

  // Dynamic Values
  StartTime = null;
  Prompt = '';
  GPTAnswer = {};

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.GPT3Engine = iProperties.GPT3Engine;

    iProperties.Classes.forEach((gpt3Class) => {
      log.debug(
        this.GetIdentity() +
          ' constructor: Adding class ' +
          gpt3Class.ID +
          " '" +
          gpt3Class.DisplayedName +
          "' '" +
          gpt3Class.Name +
          "'."
      );

      let newClass = new GPT3Class(gpt3Class.ID, gpt3Class.Name, gpt3Class.DisplayedName);
      this.Classes.push(newClass);

      this[newClass.GetPortName()] = new NodePort(newClass.GetPortName(), 'output', this);
    });

    /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", classes count = " + this.Classes.length
                    + ", GPT3 Engine = " + this.GPT3Engine);*/
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    this.StartTime = new Date();

    // Retrieve speech text from speech input node
    let speech = this.GetSourceText();

    log.debug(this.GetIdentity() + " has been activated with speech = '" + speech + "'.");

    this.AskGPTCompletion(speech);
  }

  GetSourceText() {
    let text = '';

    // Get the speech from the first connected node
    let speechNodes = this.Speech.GetConnectedNodes();
    if (speechNodes.length > 0) {
      text = speechNodes[0].GetStringValue();
    }

    return text;
  }

  async AskGPTCompletion(iSpeech) {
    // Replace [Speech] by the speech text
    this.Prompt = iSpeech + ' ->';
    log.debug(this.GetIdentity() + ': GPT-3 prompt = ', this.Prompt);

    // Ask GPT3
    this.GPTAnswer = await window.sdk
      .openaiAPI()
      .CallCompletionAPI(this.Prompt, this.GPT3Engine, 1, 0, 1, 0, 0, '');

    let classFound = null;
    if (this.GPTAnswer.status === 'success') {
      log.debug(
        this.GetIdentity() + ': After ' + this.GPTAnswer.timeSpent + ' ms, GPT-3 answer = ',
        this.GPTAnswer.completion
      );

      // Parse the answer to find the one of the classes names
      this.Classes.forEach((gpt3Class) => {
        if (this.GPTAnswer.completion.indexOf(gpt3Class.Name) !== -1) {
          classFound = gpt3Class;
        }
      });

      // Log to DynamoDB
      let className = 'Default';
      if (classFound) {
        className = JSON.stringify(classFound);
      }
      window.sdk.AnalysisTask().createOne(
        this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
        this.ID.toString(), // Node ID
        'GPT-3 Classifier', // analyzer Engine
        this.GPT3Engine, // Analyzer Version
        'raw', // Analysis Status
        this.Prompt, // Analysis Input
        this.StartTime, // Start Time
        (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
        JSON.stringify(this.Classes), // Possible classes
        JSON.stringify({
          'GPT-3 raw answer': this.GPTAnswer.completion,
          'Class found': className
        }), // Analysis Result
        this.Graph.ExerciseID.toString() // Exercise ID
      );
    } else {
      log.debug(
        this.GetIdentity() + ': After ' + this.GPTAnswer.timeSpent + ' ms, GPT-3 error = ',
        this.GPTAnswer.error
      );

      // Log to DynamoDB
      window.sdk.AnalysisTask().createOne(
        this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
        this.ID.toString(), // Node ID
        'GPT-3 Classifier', // analyzer Engine
        this.GPT3Engine, // Analyzer Version
        'failed', // Analysis Status
        this.Prompt, // Analysis Input
        this.StartTime, // Start Time
        (new Date().getTime() - this.StartTime.getTime()).toString(), // Analysis duration (milliseconds)
        JSON.stringify(this.Classes), // Possible classes
        "Failed! Reason: '" + this.GPTAnswer.status + "'. Message: '" + this.GPTAnswer.error + "'.", // Analysis Result
        this.Graph.ExerciseID.toString() // Exercise ID
      );
    }

    // Activate the corresponding port
    this.SetActive(false);
    if (classFound) {
      log.debug(this.GetIdentity() + ': Class found = ' + classFound.Name);

      let classPort = this.GetPortByName(classFound.GetPortName());
      if (classPort) {
        log.debug(this.GetIdentity() + ": Activating Class port '" + classPort.Name + "'.");
        classPort.Activate();
      } else {
        log.debug(this.GetIdentity() + ': Impossible to get Class port, activating Default port.');
        this.Default.Activate();
      }
    } else {
      log.debug(this.GetIdentity() + ': No class found, activating Default port.');
      this.Default.Activate();
    }
  }

  OnDeactivated() {
    // Stop current GPT3 question if running
    // TODO: GPT3.Instance.CancelGPT3Choice(this);

    super.OnDeactivated();
  }

  PrintParameters() {
    /*log.debug("GPT3Choice: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", Question = " + this.Question 
                    + ", GPT3 Engine = " + this.GPT3Engine 
                    + ", Max Tokens = " + this.MaxTokens 
                    + ", Temperature = " + this.Temperature 
                    + ", Top P = " + this.TopP 
                    + ", Frequency Penalty = " + this.FrequencyPenalty 
                    + ", Presence Penalty = " + this.PresencePenalty 
                    + ", Stop Sequence = " + this.StopSequence); */
  }
}

class GPT3Class {
  ID = -1;
  Name = '';
  DisplayedName = '';

  constructor(iID, iName, iDisplayedName) {
    this.ID = iID;
    this.Name = iName;
    this.DisplayedName = iDisplayedName;
  }

  GetPortName() {
    return 'Class' + this.ID;
  }

  ToString() {
    return (
      '{' +
      "\n  Class: '" +
      this.DisplayedName +
      "' '" +
      this.Name +
      "'" +
      "\n  Port: '" +
      this.GetPortName() +
      "'" +
      '\n}'
    );
  }
}
